.user {
  width: 90%;
  margin: 50px auto;
  .btn_group {
    text-align: right;
    .btn {
      @include mq-down(sm) {
        width: 100%;
      }
    }
  }
  .reset_send {
    text-align: center;
    margin-bottom: 30px;
  }
  .reset_send_btn {
    text-align: center;
    .btn-info {
      @include mq-down(md) {
        width: 100%;
      }
    }
  }
}
