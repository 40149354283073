.list {
  max-width: 1280px;
  width: 90%;
  margin: 30px auto;
  .table {
    background-color: $white;
    .no_deta {
      text-align: center;
    }
  }
  .dltable {
    width: 90%;
    border: 1px solid #cccccc;
    border-radius: 4px;
    margin: 15px;
    background-color: #fff;
    .d_frame {
      width: 25%;
    }
    .d_frame_no {
      width: 100%;
    }
    dl {
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      text-align: center;
      font-size: 12px;
      border-bottom: 1px solid #ccc;
      &:last-child {
        border-bottom: none;
      }
      dt {
        padding: 10px 0;
        background: #f2f2f2 none repeat scroll 0 0;
        color: #333;
      }
      dd {
        padding: 10px 0;
      }
      .is-history_open {
        line-height: normal !important;
        height: auto !important;
        opacity: 1 !important;
      }
      .dltable_history {
        width: 100%;
        border: 1px solid #cccccc;
        border-radius: 4px;
        margin: 5px;
        background-color: #fff;
        line-height: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
        .d_history_frame {
          width: 16.6%;
        }
      }
    }
  }
}
