@import url(https://fonts.googleapis.com/css?family=Open+Sans:400);
@import url(http://weloveiconfonts.com/api/?family=fontawesome);
@import './breakpoints.scss';
@import './colorcode.scss';
@import './login/index.scss';
@import './list/index.scss';
@import './user/index.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #e9e9e9;
  color: #5e5e5e;
  font: 400 87.5%/1.5em 'Open Sans', sans-serif;
}

.error {
  color: $red;
  text-align: center;
}

.readonly {
  background-color: $gray !important;
}

.linkWrap {
  @include mq-down(md) {
    width: 40%;
  }
  position: relative;
  cursor: pointer;
  width: 150px;
  padding: 10px;
  text-align: center;
  a {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

// .header {
//   background-color: $white;
//   padding: 20px 0;
//   nav {
//     display: flex;
//     justify-content: space-between;
//     margin: 0 40px;
//     img {
//       width: 50px;
//       height: 50px;
//     }
//     ul {
//       @include mq-down(md) {
//         width: 40%;
//       }
//       @include mq-down(sm) {
//         width: 70%;
//       }
//       width: 50%;
//       display: flex;
//       justify-content: space-around;
//       list-style-type: none;
//       align-items: center;
//       .nav_active {
//         background-color: $red;
//         color: $white;
//       }
//     }
//   }
// }

.header {
  background-color: $white;
  padding: 20px 0;
  nav {
    display: flex;
    padding: 0 50px;
    align-items: center;
    width: 100%;
    height: var(--nav-height);
    background: var(--nav-background);
    .logo {
      img {
        width: 50px;
        height: 50px;
      }
    }
    .nav-links {
      @include mq-down(md) {
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100%;
        background: var(--nav-background);
        margin-top: var(--nav-height);
        transition: 0.5s ease-out;
        background-color: $white;
        padding-top: 100px;
      }
      display: flex;
      height: var(--nav-height);
      align-items: center;
      margin-left: auto;
      li {
        @include mq-down(md) {
          width: 100%;
          display: block;
        }
        list-style: none;
        margin: 0 15px;
        height: var(--nav-height);
        line-height: var(--nav-height);
        text-align: center;
        a {
          text-decoration: none;
          color: rgb(99, 99, 99);
        }
      }
      .nav_active {
        background-color: $red;
        color: $white;
      }
    }
    .menu {
      @include mq-down(md) {
        display: block;
        margin-left: auto;
        z-index: 9999;
      }
      cursor: pointer;
      background: var(--nav-background);
      border: none;
      outline: none;
      display: none;
      .bar {
        width: 20px;
        height: 2px;
        background: #000;
        border-radius: 5px;
        opacity: 1;
        visibility: visible;
        transition: 0.5s ease;
        transform-origin: left;
      }
      .bar1 {
        margin-bottom: 5px;
      }
      .bar3 {
        margin-top: 5px;
      }
      &.menu_toggle {
        .bar1 {
          transform-origin: left;
          transform: rotate(45deg);
        }
        .bar2 {
          opacity: 0;
          visibility: hidden;
        }
        .bar3 {
          transform-origin: left;
          transform: rotate(-45deg);
        }
      }
    }
  }
  .menu.menu_toggle + .nav-links {
    @include mq-down(md) {
      transform: translateX(-100%);
    }
  }
}
