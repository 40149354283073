.login {
  [class*="fontawesome-"]:before {
    font-family: 'FontAwesome', sans-serif;
  }

  .form-wrapper {
    background: #fafafa;
    margin: 5% auto;
    padding: 0 1em;
    max-width: 370px;
  }

  h1 {
    text-align: center;
    padding: 0.5em 0;
  }

  .logo_flame {
    text-align: center;
    .login_img {
      width: 136px;
      height: 136px;
      margin: 30px auto;
    }
  }

  form {
    padding: 0 1.5em;
  }

  .form-item {
    margin-bottom: 0.75em;
    width: 100%;

    input {
      background: #fafafa;
      border: none;
      border-bottom: 2px solid #e9e9e9;
      color: #666;
      font-family: 'Open Sans', sans-serif;
      font-size: 1em;
      height: 50px;
      transition: border-color 0.3s;
      width: 100%;

      &:focus {
        border-bottom: 2px solid #c0c0c0;
        outline: none;
      }
    }
  }

  .button-panel {
    margin: 2em 0 0;
    width: 100%;

    .button {
      background: #f16272;
      border: none;
      color: #fff;
      cursor: pointer;
      height: 50px;
      font-family: 'Open Sans', sans-serif;
      font-size: 1.2em;
      letter-spacing: 0.05em;
      text-align: center;
      text-transform: uppercase;
      transition: background 0.3s ease-in-out;
      width: 100%;
    }
  }

  .button:hover {
    background: #ee3e52;
  }

  .form-footer {
    font-size: 1em;
    padding: 2em 0;
    text-align: center;

    a {
      color: #8c8c8c;
      text-decoration: none;
      transition: border-color 0.3s;

      &:hover {
        border-bottom: 1px dotted #8c8c8c;
      }
    }
  }

  .reset_link {
    margin-top: 20px;
    text-align: center;
  }
}
